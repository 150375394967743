var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',[(_vm.loading)?_c('sb-loading'):_vm._e(),_c('sb-page-header',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" "),_c('small',[_vm._v("Schooldistrictbeheerder")])])]},proxy:true},{key:"right",fn:function(){return [_c('dropdown',{attrs:{"placement":"bottom-end"}},[_c('i-button',{attrs:{"type":"primary","ghost":""}},[_vm._v(" Acties "),_c('icon',{attrs:{"type":"md-more"}})],1),_c('dropdown-menu',{staticStyle:{"width":"220px"},attrs:{"slot":"list"},slot:"list"},[_c('router-link',{attrs:{"to":{
              name:
                'ResourceManagementSchooldistrictManagersSchooldistrictManagerEdit',
            }}},[_c('sb-dropdown-button',[_vm._v(" Gegevens bewerken ")])],1),_c('router-link',{attrs:{"to":{
              name:
                'ResourceManagementSchooldistrictManagersSchooldistrictManagerEditPassword',
            }}},[_c('sb-dropdown-button',[_vm._v(" Wachtwoord wijzigen ")])],1),_c('sb-dropdown-button',{staticStyle:{"color":"red"},on:{"click":_vm.confirmArchiveUser}},[_vm._v(" Gebruiker verwijderen ")])],1)],1)]},proxy:true}],null,false,1591941728)}),_c('sb-tabbed-page',{attrs:{"data":_vm.user}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }